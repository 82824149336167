/* Resets and basic styles */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

/* Main app container */
.app {
  display: flex;
  height: 100vh;
  /* Full viewport height */
  width: 100%;
  /* Full viewport width */
}

/* Sidebar styles */
.sidebar {
  position: fixed; /* Fixed position on the side */
  top: 0;         /* Align to the top of the viewport */
  left: 0;        /* Align to the left of the viewport */
  width: calc(13% + 50px); /* As per your style */
  height: 100vh;  /* Span the full height of the viewport */
  z-index: 1000;  /* Ensure it stays on top of other content */
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #333;
  color: white;
  padding: 20px 20px;
  box-sizing: border-box;
}

.sidebar-title {
  font-size: 24px;
}

.sidebar-link {
  margin-left: 20px;
  
  color: white;
  text-decoration: none;
}

.sidebar-link {
  display: block;
  padding: 10px 0;
}

.sidebar-li {
  padding: 10px 0;
  border-top: 1px solid #444;
  cursor: pointer;
}

.sidebar-li:hover {
  background-color: #555;
}

.sidebar-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}


.navbar {
  position: fixed; 
  top: 0;
  left: calc(13% + 50px);
  right: 0;
  z-index: 1000;
  background-color: rgb(1, 22, 90)  ;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  height: 85px;
}

.navbar-title {
  padding-left: 20px;
}

/* Simplified profile setting style */
.profile-setting {
  margin-left: auto;
  /* Pushes profile to the far right */
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;
}

/* Main content styles */
.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(88% - 50px);
}

.verified {
  color: green;
}

.not-verified {
  color: red;
}

.content {
  padding-top: 25px; /* Add top padding to account for the fixed navbar */
  padding-left: calc(5% + 70px); /* Adjust padding to account for the fixed sidebar width + some extra space */
  height: 100%; /* Full height of the container */
  overflow-y: auto; /* Add scrolling to the content area if needed */
}


/* Custom padding for the card body to make it more spacious */
.card-body-custom {
  padding: 2rem;
}

/* Custom margin and padding for form groups to enhance spacing */
.form-group-custom {
  margin-bottom: 1.5rem;
}

/* Custom style for the button to make it stand out */
h1, h2 {
  color: #007bff;  /* Using Bootstrap primary blue for consistency */
}

/* Increase the weight and size for the main title for emphasis */
h1 {
  font-weight: bold;
  font-size: 2.5rem; /* Adjust size as needed */
}

/* Subtle and smaller subtitle */
h2 {
  font-size: 1.8rem;
  color: #343a40;  /* Darker shade for less emphasis compared to the main title */
}